export const UPDATE_PASSWORD = "Update Password";
export const UPDATE_DETAILS = "Update Details";
export const UPDATE = "Update";
export const KYC_STATUS = "KYC Status";
export const EDD_STATUS = "EDD Status";
export const KYC_VERIFICATION = "KYC Verification";
export const WHITELISTS = "Whitelist";
export const ACCOUNT_SETTING = "Account Setting";
export const SECURITY = "Security";
export const DEPOSIT_THRESHOLD_LIMIT = "Deposit Threshold Limit";
export const DEPOSIT_LIMIT_PROMPT =
  "(To increase the limit, please contact support)";
export const RESET_2FA = "Reset 2FA";
export const USER_LOGS = "User Logs";
export const IDENTIFICATION = "Identification";
export const NOT_VERIFIED = "Not Verified";
export const REJECTED = "Rejected";
export const IN_PROGRESS = "In Progress";
export const VERIFIED = "Verified";
export const GOOGLE_2FA_ENABLED = "google2faEnabled";
export const DEPOSIT_RANGE = "depositRange";
export const PAYMENTS = "Payments";
export const FIRST_NAME = "firstName";
export const LAST_NAME = "lastName";
export const DOB = "dob";
export const CONTACT_NUMBER = "contactNumber";
export const EMAIL = "email";
export const PROFILE_UPDATE_CONFIRM_HEADING = "Profile Update Confirmation";
export const PROFILE_UPDATE_CONFIRM_SUBHEADING =
  "Updating your profile may require additional KYC checks and information. Trading activity may be limited while re-verification is in progress";
export const RESET_2FA_VERIFICATION = "Reset 2FA Verification";
export const VERIFICATION_CODE = "verificationCode";
export const NEW_2FA_KEY = "Your 2FA secret key is: ";
export const NEW_2FA_HEADING =
  "If you change your phone, you can either import this secret key into your Google Authenticator app or ask your administration to disable your 2FA settings in order to login again.";
export const NEW_2FA_SUBHEADING =
  "Scan the QR Code below with your Google Authenticator App.";
export const RESET = "Reset";
export const TWO_FA_SECRET_CODE_COPIED = "2FA Secret Key Copied";
export const UPDATE_DETAILS_MODAL_MESSAGE =
  "If your details have changed and you require us to update your account, please contact";
export const DISABLE_2FA_MESSAGE =
  "If you want to disable 2FA, please contact support from your registered account email address.";
export const DISABLE_2FA = "Disable 2FA";
export const CHANGE_EMAIL = "Change Email";
export const VERIFY_CURRENT_PASSWORD = "Verify Current Password";
export const SUBMIT = "Submit";
export const EMAIL_CHANGED = "Email address changed successfully";
export const EMAIL_NOT_CHANGED =
  "Unable to change email address! Please try again later.";
export const LOGIN = "Login";
export const CURRENT_PASSWORD = "currentPassword";
export const PASSWORD = "password";
export const CONFIRM_PASSWORD = "confirmPassword";
export const CANCEL = "Cancel";
export const PLEASE_WAIT = "Please wait...";
export const NAME_CHANGE_WARNING_HEADING = "Name Change Confirmation";
export const NAME_CHANGE_WARNING_SUBHEADING =
  "Updating your profile may require additional KYC checks and information. Trading activity may be limited while re-verification is in progress";
export const PENDING = "pending";
export const SUCCESS = "success";
export const FAILED = "failed";
export const COPY = "Copy";
export const KYC_PENDING = "Pending";
